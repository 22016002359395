import * as toastr from 'toastr'
import './notify.css'

export function notification(title, message, state) {
  toastr[state](message, title, {
    positionClass: 'toast-bottom-right',
    progressBar: true,
    showMethod: 'slideDown',
    hideMethod: 'slideUp',
    showDuration: 200,
    hideDuration: 200,
    closeButton: true,
    timeOut: 9999,
    extendedTimeOut: 200,
    newestOnTop: false
  })
  if (document.querySelectorAll('.toast').length > 8) {
    document.querySelectorAll('.toast')[0].remove()
  }
}

export const apiNotify = (data, success = () => { }, successMsg = '') => {
  if (data == 'success') {
    success()
    if (successMsg) {
      notification('SUCCESS', successMsg, 'success')
    }
  }
  else {
    notification(data, '', 'error')
  }
}

export const apiError = (message = '', onerror = () => { }) => {
  onerror()
  notification('Internal Server Error', message, 'error')
}