// -- IMPORTS --
import React from 'react'
import PropTypes from 'prop-types'

// mui components
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import api from '../root/api-client'
import useMediaQuery from '@mui/material/useMediaQuery'

// -- EXPORTED REACT COMPONENT --
const ThemeWrapper = (props) => {
  // -- STATES --
  const [theme, setTheme] = React.useState('system')

  // -- PROPS --
  const {loginPage, component} = props

  let preferredMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'

  React.useEffect(() => {
    document.body.style.colorScheme = theme
    if (theme == 'light') {
      document.body.style.backgroundColor = 'rgba(230, 230, 230, 1)'
    } else if (theme == 'dark') {
      document.body.style.backgroundColor = ''
    } else {
      if (preferredMode == 'light') {
        document.body.style.backgroundColor = 'rgba(230, 230, 230, 1)'
      } else {
        document.body.style.backgroundColor = ''
      }
    }
  }, [preferredMode])

  // -- USEEFFECTS --
  React.useEffect(() => {
    getTheme()
  }, [])

  React.useEffect(() => {
    updateTheme()
    document.body.style.colorScheme = theme
    if (theme == 'light') {
      document.body.style.backgroundColor = 'rgba(230, 230, 230, 1)'
    } else if (theme == 'dark') {
      document.body.style.backgroundColor = ''
    } else {
      if (preferredMode == 'light') {
        document.body.style.backgroundColor = 'rgba(230, 230, 230, 1)'
      } else {
        document.body.style.backgroundColor = ''
      }
    }
  }, [theme])

  // -- FUNCTIONS --
  const getTheme = () => {
    if (loginPage) {
      if (localStorage.getItem('vatlnetwork_theme') != 'dark') {
        document.body.style.backgroundColor = 'rgba(230, 230, 230, 1)'
      }
      return
    }
    else {
      api.get('/user_info/all').then(({ data }) => {
        if (data.theme == 'dark') {
          setTheme('dark')
          localStorage.setItem('vatlnetwork_theme', 'dark')
        } else if (data.theme == 'light') {
          setTheme('light')
          localStorage.setItem('vatlnetwork_theme', 'light')
        } else {
          setTheme('system')
          localStorage.setItem('vatlnetwork_theme', 'system')
        }
        if (data.backgroundImage != null && data.backgroundImage != 'none') {
          document.body.style.backgroundImage = `url(${data.backgroundImage})`
          document.body.style.backgroundRepeat = 'no-repeat'
          document.body.style.backgroundSize = 'cover'
          document.body.style.backgroundPosition = 'center'
        }
      })
    }
  }

  const updateTheme = () => {
    setInterval(() => {
      let localTheme = localStorage.getItem('vatlnetwork_theme')?.toString()
      setTheme(localTheme ? localTheme : 'system')
    }, 10)
  }

  // -- VARIABLES --
  const autoTheme = createTheme({
    palette: {
      mode: useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'
    }
  })

  const muiTheme = createTheme({
    palette: {
      mode: theme == 'dark' ? 'dark' : 'light'
    }
  })

  // -- RETURN STATEMENT --
  return (
    <ThemeProvider theme={theme == 'system' ? autoTheme : muiTheme} >
      <CssBaseline />
      <div style={{padding: '5px'}} >
        {component}
      </div>
    </ThemeProvider>
  )
}

// -- PROP TYPES --
ThemeWrapper.propTypes = {
  component: PropTypes.element.isRequired
}

export default ThemeWrapper