// -- IMPORTS --
import React from 'react'
import PropTypes from 'prop-types'

// mui components 
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

// mui icons
import PasswordIcon from '@mui/icons-material/Password'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

// -- EXPORTED REACT COMPONENT --
const PasswordField = (props) => {
  // -- PROPS --
  const {value, setValue, showPassword, autoFocus, fullWidth, disabled, setShowPassword, onKeyUp, fieldId} = props

  // -- RETURN STATEMENT --
  return (
    <TextField
      size={'small'}
      label={'Password'}
      value={value}
      onChange={(e) => { setValue(e.target.value) }}
      type={showPassword ? 'text' : 'password'}
      autoFocus={autoFocus ? true : false}
      fullWidth={fullWidth ? true : false}
      disabled={disabled ? true : false}
      id={fieldId ? fieldId : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'} >
            <IconButton size={'small'} disabled >
              <PasswordIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position={'end'} >
            <IconButton
              size={'small'}
              onClick={() => { setShowPassword(!showPassword) }}
              tabIndex={-1}
            >
              {showPassword ?
                <VisibilityOffIcon /> :
                <VisibilityIcon />
              }
            </IconButton>
          </InputAdornment>
        )
      }}
      onKeyUp={onKeyUp ? onKeyUp : () => {}}
    />
  )
}

PasswordField.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  showPassword: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  setShowPassword: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  fieldId: PropTypes.string
}

export default PasswordField