// import api from './api-client'
// import { notification } from './parts/notify'

export const globalvars = {
  backgroundColor: 'rgba(230, 230, 230, 1)'
}

export const setbgcolor = () => {
  document.body.style.backgroundColor = globalvars.backgroundColor
}

// export const sendNotification = (userId, icon, title, body) => {
//   let day = new Date().getDate()
//   let month = new Date().getMonth() + 1
//   let year = new Date().getFullYear()
//   let time = new Date().toLocaleTimeString()
//   api.post('/notifications/send_notification', {
//     user_id: userId,
//     icon: icon,
//     title: title,
//     body: body,
//     day_sent: day,
//     month_sent: month,
//     year_sent: year,
//     time_sent: time
//   }, () => {
//     notification('500 Internal Server Error', 'notifications/send', 'error')
//   })
// }

export const focusField = (elId) => {
  document.getElementById(elId)?.focus()
}

export const dom = {
  focusField: (elId) => {
    document.getElementById(elId).focus()
  },
  getValue: (elId) => {
    return document.getElementById(elId).value
  },
  setbgcolor: () => {
    document.body.style.backgroundColor = globalvars.backgroundColor
  },
  setValue: (elId, value) => {
    document.getElementById(elId).value = value
  }
}

export const redirect = (url) => {
  window.location.assign(`${window.location.origin}${url}`)
}

export const genRandomColor = () => {
  let red = Math.floor(Math.random() * 255)
  let green = Math.floor(Math.random() * 255)
  let blue = Math.floor(Math.random() * 255)
  return `rgba(${red}, ${green}, ${blue}, 1)`
}