// -- IMPORTS --
import React from 'react'

// mui components
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// -- EXPORTED REACT COMPONENT --
const EasyTabs = (props) => {
  // -- STATES --
  const [tabPanels, setTabPanels] = React.useState<JSX.Element[]>([])
  const [tabLists, setTabLists] = React.useState<JSX.Element[]>([])

  // -- PROPS --
  // tabs has to be in the format [{label: string, value: string | number, content: React Component}]
  const {tabs, currentTab, setCurrentTab, disabled} = props

  // -- USEEFFECTS --
  React.useEffect(() => {
    setTabPanels([])
    setTabLists([])
    let tempTabPanel = (<></>)
    let tempTabList = (<></>)
    let tempTabPanels : JSX.Element[] = []
    let tempTabLists : JSX.Element[] = []
    tabs.forEach((tab) => {
      tempTabPanel = (
        <TabPanel value={tab.value} key={tab.value} >{tab.content}</TabPanel>
      )
      tempTabPanels.push(tempTabPanel)
      tempTabList = (
        <Tab label={tab.label} value={tab.value} key={tab.value} />
      )
      tempTabLists.push(tempTabList)
    })
    setTabPanels(tempTabPanels)
    setTabLists(tempTabLists)
  }, [tabs])

  // -- RETURN STATEMENT --
  return (
    <TabContext value={currentTab} >
      <TabList
        onChange={(_, newValue) => { if (!disabled) {setCurrentTab(newValue)} }}
        variant={'fullWidth'}
      >
        {tabLists}
      </TabList>
      {tabPanels}
    </TabContext>
  )
}

export default EasyTabs